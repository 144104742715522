import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const config = JSON.parse(process.env.NEXT_PUBLIC_FB_CONFIG)

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
